<template>
  <a
    :href="data.url"
    class="item-top"
    target="_blank"
    @click="reportClickResult"
    @contextmenu="reportClickResult"
  >
    <div class="item-url">{{ data.url }}</div>
    <h3>
      {{ data.data.title }}
    </h3>
  </a>
  <div class="item-bottom">
    <div class="flex no-wrap">
      <a
        :href="data.url"
        target="_blank"
        @click="reportClickResult"
        @contextmenu="reportClickResult"
      >
        <img
          :src="data.data.img_url"
          :alt="`${data.data.title} 的封面图`"
          width="180"
          class="q-mr-sm"
        />
      </a>
      <div>
        <p v-html="data.data.content" style="height: 80%"></p>
        <div class="badges-row-container">
          <span
            class="badge-category-bg"
            style="background-color: #3d3fea"
          ></span>
          <span class="from">PingCAP Education</span>
          <div class="score" v-if="user && user.isStaff">
            <q-badge color="blue">Score: {{ data.score }}</q-badge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reportClick } from "../../api/search";

export default {
  props: ["data", "user", "searchTrackId"],
  setup(props) {
    function reportClickResult() {
      reportClick(props.data.url, props.searchTrackId);
    }

    return {
      reportClickResult,
    };
  },
};
</script>

<style lang="sass" scoped>
@import '../../styles/resultItem.sass'
</style>