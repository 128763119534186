<template>
  <div class="no-results">
    <p>
      找不到和你查询的 <em>{{ keyword.slice(0, 20) }}</em> 相关的内容
    </p>
    <p>建议:</p>
    <ul>
      <li>请检查输入字词有无错误。</li>
      <li>请尝试其他查询词。</li>
      <li>换一种角度提问。</li>
    </ul>
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "NoResult",
  props: ["keyword"],
};
</script>
