<template>
  <div>
    <div class="title">没有想要的答案? 您还可以</div>
    <a href="https://asktug.com/?utm_source=search" target="_blank">
      <div class="card">
        <div class="desc">
          <div class="title">求助社区</div>
          <div class="content">前往问答论坛发帖询问</div>
        </div>
        <img src="../assets/ask_community.svg" height="56" width="56" />
      </div>
    </a>
    <a href="mailto:info@pingcap.com" style="display: none;">
      <div class="card">
        <div class="desc">
          <div class="title">联系我们</div>
          <div class="content">寻求技术专家的帮助</div>
        </div>
        <img src="../assets/contact_us.svg" height="56" width="56" />
      </div>
    </a>
  </div>
</template>

<style lang="sass" scoped>
a
  text-decoration: none

.title
  font-size: 18px
  font-weight: 500
  margin-bottom: 12px

.card
  display: flex
  padding: 12px
  margin-top: 12px
  height: 88px
  width: 345px
  justify-content: space-between
  background-color: rgb(233, 234, 238, .4)
  border-radius: 6px
  cursor: pointer

  &:hover
    box-shadow: 0px 2px 6px rgb(233, 234, 238)

  .desc
    font-size: 16px

    .title
      color: #2c2c2c
      font-weight: 500

    .content
      color: #565656
      margin-top: 5px

  img
    height: 56px
    width: 56px
</style>
