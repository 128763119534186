<template>
  <a
    :href="data.url"
    class="item-top"
    target="_blank"
    @click="reportClickResult"
    @contextmenu="reportClickResult"
  >
    <div class="item-url">{{ data.url }}</div>
    <h3>
      {{ data.data.title }}
    </h3>
  </a>
  <div class="item-bottom">
    <p v-html="data.data.content"></p>
    <p v-if="!data.data.content">......(点击链接 ↑ 查看详情)</p>
    <div class="badges-row-container">
      <span class="badge-category-bg" style="background-color: #92278F"></span>
      <span class="from">官方文档</span>
      <div class="score" v-if="user && user.isStaff">
        <q-badge color="blue">Score: {{ data.score }}</q-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { reportClick } from "../../api/search";

export default {
  props: ["data", "user", "searchTrackId"],
  setup(props) {
    function reportClickResult() {
      reportClick(props.data.url, props.searchTrackId);
    }

    return {
      reportClickResult,
    };
  },
};
</script>

<style lang="sass" scoped>
@import '../../styles/resultItem.sass'
</style>