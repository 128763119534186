<template>
  <div>
    <div v-if="searchSource !== ''">找到 {{ resultsMeta.total }} 条结果</div>
    <div v-else>已筛选出 {{ resultsMeta.total }} 条结果</div>

    <div>
      <div v-for="item in hits" :key="item.url">
        <AskTUGItem
          :data="item"
          :user="user"
          :searchTrackId="searchTrackId"
          v-if="item.source === 'asktug'"
        />
        <BlogItem
          :data="item"
          :user="user"
          :searchTrackId="searchTrackId"
          v-if="item.source === 'blog'"
        />
        <DocItem
          :data="item"
          :user="user"
          :searchTrackId="searchTrackId"
          v-if="item.source === 'docs-cn'"
        />
        <CommunityBlogItem
          :data="item"
          :user="user"
          :searchTrackId="searchTrackId"
          v-if="item.source === 'community-blog'"
        />
        <PEItem
          :data="item"
          :user="user"
          :searchTrackId="searchTrackId"
          v-if="item.source === 'pe'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AskTUGItem from "./resultItem/asktug.vue";
import BlogItem from "./resultItem/blog.vue";
import DocItem from "./resultItem/doc.vue";
import CommunityBlogItem from "./resultItem/communityBlog.vue";
import PEItem from "./resultItem/pe.vue";

export default {
  props: ["resultsMeta", "hits", "searchTrackId", "searchSource", "user"],
  components: {
    AskTUGItem,
    BlogItem,
    DocItem,
    CommunityBlogItem,
    PEItem,
  },
  setup() {
    return {};
  },
};
</script>