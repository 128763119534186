<template>
  <div id="search-index-wrapper">
    <div
      class="search-container"
      @focus.capture="foucsInputFlag = true"
      @blur.capture="searchLostFoucs"
    >
      <div class="flex top">
        <q-icon name="search" />
        <div class="input-containner">
          <input
            type="text"
            name="q"
            ref="search"
            maxlength="1024"
            autocomplete="off"
            autocorrect="off"
            spellcheck="false"
            autocapitalize="off"
            title="AskTUG 搜索"
            placeholder="输入关键词, 按回车搜索"
            v-model="keyword"
            v-on:keyup.enter="refreshAndSearch"
          />
        </div>
      </div>
      <div
        class="suggestions"
        v-if="
          foucsInputFlag &&
          keywordSuggestions !== undefined &&
          keywordSuggestions.length > 0
        "
      >
        <div class="divider"></div>
        <ul>
          <router-link v-for="s in keywordSuggestions" :key="s" :to="`/?q=${s}`"
            ><li>{{ s }}</li></router-link
          >
        </ul>
      </div>
    </div>
    <div class="search-types-wrapper">
      <ul class="search-types-container">
        <router-link :to="`/?q=${keyword || ''}`" tag="li" class="item">
          <q-icon name="zoom_in" />全部
        </router-link>
        <router-link :to="`/asktug?q=${keyword || ''}`" tag="li" class="item">
          <q-icon name="forum" />问答
        </router-link>
        <router-link
          :to="`/community-blog?q=${keyword || ''}`"
          tag="li"
          class="item"
        >
          <q-icon name="newspaper" />专栏
        </router-link>
        <router-link :to="`/doc?q=${keyword || ''}`" tag="li" class="item">
          <q-icon name="description" />官方文档
        </router-link>
      </ul>
    </div>
    <div class="flex">
      <div class="result-container">
        <EmptyKeywords v-if="!keyword && hits.length === 0" />
        <NoResult
          :keyword="keyword"
          v-if="keyword && resultsMeta.total === 0"
        />
        <Results
          :hits="hits"
          :resultsMeta="resultsMeta"
          :user="user"
          :searchTrackId="searchTrackId"
          :searchSource="searchSource"
          v-if="hasResults && resultsMeta.total"
        />
        <div class="q-pa-lg flex flex-center" v-if="maxPage !== 0">
          <q-pagination
            color="red-10"
            v-model="page"
            :max="maxPage"
            :max-pages="6"
            boundary-numbers
            direction-links-
          />
        </div>
      </div>
      <ResultRightSide class="result-right-side" />
    </div>
  </div>
</template>

<script>
import { ref, defineComponent, onMounted, getCurrentInstance, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useQuasar, debounce } from "quasar";
import ClickOutside from "vue-click-outside";

import ResultRightSide from "../components/ResultRightSide.vue";
import NoResult from "../components/NoResult.vue";
import EmptyKeywords from "../components/EmptyKeywords.vue";
import Results from "../components/Results.vue";
import {
  searchKeyword,
  getKeywordSuggestions as getKeywordSuggestionsFromServer,
} from "../api/search";

export default defineComponent({
  components: {
    ResultRightSide,
    NoResult,
    EmptyKeywords,
    Results,
  },
  directives: {
    ClickOutside,
  },
  setup() {
    const route = useRoute();
    const user = ref(null);
    const keyword = ref(route.query.q || "");
    const hasResults = ref(false);
    const resultsMeta = ref({});
    const hits = ref([]);
    const page = ref(1);
    const perPage = ref(10);
    const maxPage = ref(0);
    const searchTrackId = ref("");
    const searchSource = ref("");
    const router = useRouter();
    const $q = useQuasar();
    const foucsInputFlag = ref(false);
    const keywordSuggestions = ref([]);

    onBeforeMount(() => {
      keyword.value = route.query.q || "";
      user.value = window.config.user;
      if (route.name !== "index") {
        searchSource.value = route.name;
      }
      fetchResults();
    });

    onMounted(() => {
      getCurrentInstance().refs.search.focus()
    });

    function fetchResults() {
      if (!keyword.value) {
        return;
      }

      hasResults.value = true;
      $q.loading.show();

      searchKeyword(
        keyword.value,
        page.value,
        perPage.value,
        searchSource.value,
        searchTrackId.value
      )
        .then((resp) => {
          let respData = resp.data.data;
          resultsMeta.value = respData;
          page.value = respData.page_num;
          perPage.value = respData.per_page;
          searchTrackId.value = respData.track;
          hits.value = respData.hits;
          maxPage.value = Math.ceil(respData.total / respData.per_page);

          if (respData.total === 0) {
            hasResults.value = false;
          }
        })
        .finally(() => {
          $q.loading.hide();
        });
    }

    function refreshAndSearch() {
      router.push({ query: { q: keyword.value } });
    }

    const getKeywordSuggestions = debounce((keyword) => {
      keywordSuggestions.value = [];
      if (foucsInputFlag.value && keyword) {
        getKeywordSuggestionsFromServer(keyword).then((resp) => {
          keywordSuggestions.value = resp.data.data || [];
        });
      }
    }, 1000);

    function searchLostFoucs() {
      setTimeout(() => {
        foucsInputFlag.value = false;
      }, 250);
    }

    return {
      user,
      keyword,
      hasResults,
      resultsMeta,
      hits,
      page,
      perPage,
      maxPage,
      searchTrackId,
      searchSource,
      foucsInputFlag,
      keywordSuggestions,

      refreshAndSearch,
      fetchResults,
      getKeywordSuggestions,
      searchLostFoucs,
    };
  },
  watch: {
    page: function () {
      window.scroll(0, 0);
      this.hits = [];
      this.fetchResults();
    },
    keyword: function (val) {
      this.getKeywordSuggestions(val || "");
    },
  },
});
</script>

<style lang="sass">
// #tidb-community-header
//   .ti-site-header
//     box-shadow: 0 2px 6px rgb(0 0 0 / 15%)
//     border-bottom: 1px solid #e9e9e9
#tidb-community-footer
  h4
    font-size: 16px !important
    line-height: 24px
</style>

<style lang="sass" scoped>
#search-index-wrapper
  margin: 80px 0 10px 80px
  max-width: 1200px
  box-sizing: border-box
  background: #fff

.search-container
  position: absolute
  top: 90px
  box-sizing: border-box
  background-color: #fff
  border: 1px solid #dfe1e5
  box-shadow: none
  max-width: 700px
  width: 100%
  border-radius: 24px
  z-index: 1000
  padding: 5px 0

  &:hover
    box-shadow: 0px 2px 8px 1px rgb(176 172 180 / 24%)

  &:focus-within
    box-shadow: 0px 2px 8px 1px rgb(176 172 180 / 24%)

  .top
    height: 42px

  .q-icon
    margin: 0 0 0 10px
    line-height: 38px
    height: 42px
    width: 42px
    background: transparent
    border: none
    cursor: pointer
    font-size: 1.3rem
    user-select: none
    outline: none

  .input-containner
    display: flex
    flex: 1
    flex-wrap: wrap

  input
    background-color: transparent
    border: none
    margin: 0
    padding: 0
    color: rgba(0,0,0,.87)
    word-wrap: break-word
    outline: none
    display: flex
    flex: 100%
    -webkit-tap-highlight-color: transparent
    margin-top: 3px
    height: 34px
    font-size: 16px

    &::placeholder
      font-size: .875rem
      color: #bbb

  .suggestions
    .divider
      margin: 0 10px
      border-top: 1px solid #d0d0d0

    ul
      list-style: none
      padding: 0
      margin: 5px 0

      a
        text-decoration: none
        color: #000
        cursor: pointer
        font-size: 1rem

      li
        padding-left: 20px
        line-height: 2rem

        &:hover
          background-color: #fff0f0
          border-left: 2px solid #be1d32

.search-types-wrapper
  margin-top: 75px
  color: #666
  font-size: 13px
  outline: none
  position: relative
  z-index: 102
  overflow: hidden
  overflow-x: auto

.search-types-container
  display: flex
  list-style: none
  padding: 0
  margin: 0

  .item
    color: #000
    padding: 10px 10px 10px 0
    margin-left: 13px
    cursor: pointer
    white-space: nowrap
    text-decoration: none

    .q-icon
      margin-right: 4px

    &.router-link-exact-active
      border-bottom: 2px solid #be1d32
      color: #be1d32

.result-container
  max-width: 700px
  min-height: 500px
  flex: 1 1 auto
  margin-top: 10px
  padding: 10px 0 0 10px

.result-right-side
  margin-left: 100px

@media screen and (max-width:700px)
  #search-index-wrapper
    margin: 90px 0 10px 10px

    .search-container
      width: 95%

    .result-right-side
      display: none

@media screen and (min-width:700px)
  #search-index-wrapper
    margin: 90px auto
    padding: 0 10px
</style>
